<template>
  <b-card>
    <b-modal
      id="fintech-institution-service-modal"
      size="xl"
      :title="`${updateId ? 'Update' : 'Add'} Fintech Institute Service`"
      :ok-title="`${updateId ? 'Update' : 'Save'}`"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok.prevent="handleFintechInstitutionServiceFormSubmit"
      @cancel="resetFintechInstitutionService"
      @close="resetFintechInstitutionService"
    >
      <b-form @submit.prevent>
        <b-row>
          <!-- Bank -->
          <b-col cols="12">
            <b-form-group
              label="Fintech"
              label-cols-md="4"
              :state="fintechIdState"
              invalid-feedback="Fintech is required"
            >
              <v-select
                v-model="formData.fintechId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                placeholder="Select Fintech"
                :options="fintechOptions"
                :reduce="val => val.id"
                :state="fintechIdState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Status"
              label-cols-md="4"
              :state="statusState"
              invalid-feedback="Fintech ID is required"
            >
              <b-form-radio-group
                v-model="formData.status"
                :options="fintechStatusOptions"
                name="radio-inline"
                :state="statusState"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <b-row class="mt-2">
                <b-col class="d-flex">
                  <b-form-group
                    label="Fintech Service"
                    class="col-3"
                    :state="childServiceState"
                    invalid-feedback="Service is required"
                  >
                    <v-select
                      v-model="childFormData.service"
                      :disabled="!formData.fintechId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :get-option-label="option => option.payment_service.name"
                      placeholder="Select Service"
                      :options="fintechServiceOptions"
                      :state="childServiceState"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Convenience Fee"
                    class="col-3"
                    :state="childConvenienceFeeState"
                    invalid-feedback="Fee is required"
                  >
                    <b-form-input
                      v-model="childFormData.convenienceFee"
                      placeholder="Enter Convenience Fee"
                      :state="childConvenienceFeeState"
                      type="number"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Type"
                    class="col-2"
                    :state="childConvenienceTypeState"
                    invalid-feedback="Type is required"
                  >
                    <v-select
                      v-model="childFormData.convenienceType"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="text"
                      placeholder="Select Type"
                      :options="typeOptions"
                      :reduce="val => val.value"
                      :state="childConvenienceTypeState"
                    />
                  </b-form-group>
                  <b-form-group
                    label="Status"
                    class="col-2"
                    :state="childStatusState"
                    invalid-feedback="Status is required"
                  >
                    <v-select
                      v-model="childFormData.status"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="text"
                      placeholder="Select Status"
                      :options="statusOptions"
                      :reduce="val => val.value"
                      :state="childStatusState"
                    />
                  </b-form-group>
                  <b-form-group
                    label-sr-only
                    label="Submit"
                    class="col-2 align-self-end"
                  >
                    <b-button
                      type="submit"
                      variant="outline-primary"
                      class="mr-1"
                      @click.prevent="handleChildFormSubmit"
                    >
                      {{ paymentServiceUpdateMode ? "Update" : "Add" }}
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="ml-1">
                  <span
                    v-if="serviceInfoState === false"
                    :class="[
                      serviceInfoState === false
                        ? 'text-danger'
                        : ' d-none',
                    ]"
                  >
                    At least one service needs to be included
                  </span>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <!-- Child Grid -->
      <vue-good-table
        :columns="modalColumns"
        :rows="finalServiceData"
        :rtl="direction"
        :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
        :line-numbers="true"
        theme="my-theme"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span class="d-flex">
              <b-button
                class="mr-1 px-1"
                variant="outline-warning"
                @click="editPaymentService(props.row)"
              >Edit</b-button>
              <b-button
                class="px-1"
                variant="outline-danger"
                @click.prevent="deletePaymentService(props.row)"
              >Remove</b-button>
            </span>
          </span>

          <span v-else-if="props.column.field === 'convenienceType'">
            {{
              props.formattedRow[props.column.field] == 1
                ? "Percentage"
                : "Fixed"
            }}
          </span>

          <span v-else-if="props.column.field === 'status'">
            {{
              props.formattedRow[props.column.field] == 1
                ? "Active"
                : "Inactive"
            }}
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10', '20', '30', '40', '50']"
                class="mx-1"
                @input="
                  value => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="value => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-modal>

    <div class="d-flex justify-content-between">
      <p class="card-title ">
        Fintech Institute Service
      </p>
      <b-link
        variant="outline-info mx-2"
        type="button"
        @click="onActivityLog"
      >
        Activity log
      </b-link>
    </div>
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <b-button
          variant="outline-primary"
          style="text-transform: capitalize"
          @click="openModal"
        >
          Add Fintech Institute Service
        </b-button>
      </b-form-group>
      <b-form-group class="pr-0 col-lg-4 col-md-4">
        <v-select
          v-model="institutionId"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          placeholder="Select Institute"
          :options="institutionOptions"
          :reduce="val => val.id"
        />
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :pagination-options="{
        enabled: true,
        perPage: pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span class="d-flex">
            <b-button
              class="mr-1 px-1"
              variant="outline-warning"
              @click="editRow(props.row)"
            >Edit</b-button>
            <b-button
              class="px-1"
              variant="outline-danger"
              @click="deleteRow(props.row)"
            >Delete</b-button>
          </span>
        </span>

        <span v-else-if="props.column.field === 'status'">
          {{
            props.formattedRow[props.column.field] == 1 ? "Active" : "Inactive"
          }}
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10', '20', '30', '40', '50']"
              class="mx-1"
              @input="value => props.perPageChanged({ currentPerPage: value })"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div class="d-flex">
            <div class="mr-2">
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="value => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
            <div
              slot="table-actions"
              class="mt-1"
            >
              <b-button
                class="mr-1 px-1"
                variant="outline-primary"
                @click="refreshGrid()"
              >
                <RefreshCwIcon />
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- Audit Activity -->
    <activity-modal
      v-if="activityModal"
      ref="activityModal"
      :tag="tag"
    />

  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BForm, BFormInput, BFormSelect, BPagination, BButton, BFormRadioGroup, BLink,
  // BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { RefreshCwIcon } from 'vue-feather-icons'
import FintechInstituteService from '@/@service/api/accounting/fintech/FintechInstitute.service'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
import ActivityModal from '../audit/ActivityModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BCard,
    VueGoodTable,
    BFormInput,
    BFormSelect,
    BPagination,
    BButton,
    BFormRadioGroup,
    vSelect,
    RefreshCwIcon,
    BLink,
    ActivityModal,
    // BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      updateId: null,
      paymentServiceUpdateMode: false,
      columns: [
        {
          label: 'Fintech Name',
          field: 'fintech.name',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      modalColumns: [
        {
          label: 'Service',
          field: 'service.payment_service.name',
        },
        {
          label: 'Convenience Fee',
          field: 'convenienceFee',
          sortable: false,
        },
        {
          label: 'Convenience Type',
          field: 'convenienceType',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      institutionId: null,
      formData: {
        serviceType: 1,
        categories: [],
        status: 1,
      },
      childFormData: {},
      finalServiceData: [],
      institutionOptions: [],
      fintechServiceOptions: [],
      fintechOptions: [],
      coaOptions: [],
      fintechStatusOptions: [
        { text: 'Active', value: 1 },
        { text: 'Inactive', value: 0 },
      ],
      typeOptions: [
        { text: 'Percentage', value: 1 },
        { text: 'Fixed', value: 2 },
      ],
      statusOptions: [
        { text: 'Active', value: 1 },
        { text: 'Inactive', value: 0 },
      ],

      fintechIdState: null,
      statusState: null,
      serviceInfoState: null,

      childServiceState: null,
      childConvenienceTypeState: null,
      childConvenienceFeeState: null,
      childStatusState: null,

      tag: 'fintech-institute-service',
      activityModal: false,
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {
    institutionId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getFintechInstitutionServiceGridData()
      } else {
        this.rows = []
      }
    },
    'formData.fintechId': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (!this.updateId) this.finalServiceData = []
        this.serviceInfoState = null
        this.getFintechInstitutionServiceService()
      }
    },
  },
  created() {
    this.getInstitutions()
  },
  methods: {
    getInstitutions() {
      FintechInstituteService.getAllInstitution().then(res => {
        this.institutionOptions = res.data.data
        this.institutionId = this.institutionOptions[0].id
        if (!this.institutionId) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Institution Id not found',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    getFintechs() {
      FintechInstituteService.getAllFintech().then(res => {
        this.fintechOptions = res.data.data
      })
    },
    getFintechInstitutionServiceService() {
      if (this.formData.fintechId) {
        this.$http.get(`${window.apiUrl}accounting/fintech-service/service/${this.formData.fintechId}`)
          .then(res => {
            this.fintechServiceOptions = res.data.data
          })
      } else {
        this.fintechServiceOptions = []
        this.childFormData = {}
      }
    },
    handleFintechInstitutionServiceFormSubmit() {
      if (this.updateId) {
        this.updateFintechInstitutionService()
      } else {
        this.saveFintechInstitutionService()
      }
    },
    childFormValidation() {
      this.childServiceState = null
      this.childConvenienceTypeState = null
      this.childConvenienceFeeState = null
      this.childStatusState = null

      if (!this.childFormData.convenienceFee) {
        this.childConvenienceFeeState = false
      }
      if (!this.childFormData.convenienceType) {
        this.childConvenienceTypeState = false
      }
      if (!this.childFormData.service) {
        this.childServiceState = false
      }
      if (this.childFormData.status === null || typeof this.childFormData.status === 'undefined') {
        this.childStatusState = false
      }

      if (this.childConvenienceFeeState !== false && this.childConvenienceTypeState !== false
          && this.childServiceState !== false && this.childStatusState !== false) {
        return true
      }
      return false
    },
    handleChildFormSubmit() {
      if (!this.childFormValidation()) {
        return
      }

      if (this.finalServiceData.find(x => x.service.id === this.childFormData.service.id) && !this.paymentServiceUpdateMode) {
        FemsToastrService.warning('This Service Already Exist')
        this.childFormData = {}
        return
      }
      console.log(this.childFormData)

      if (this.childFormData.index || this.childFormData.index === 0) {
        const tempFinalServiceData = this.finalServiceData
        this.finalServiceData = []

        const tempServiceData = {
          id: tempFinalServiceData[this.childFormData.index].id,
          convenienceFee: this.childFormData.convenienceFee,
          convenienceType: this.childFormData.convenienceType,
          service: this.childFormData.service,
          status: this.childFormData.status,
        }

        console.log(tempServiceData)

        tempFinalServiceData.forEach((v, k) => {
          if (k === this.childFormData.index) {
            this.finalServiceData[k] = tempServiceData
          } else {
            this.finalServiceData[k] = v
          }
        })
        console.log(this.finalServiceData)
        this.paymentServiceUpdateMode = false
      } else {
        this.finalServiceData.push({
          service: this.childFormData.service,
          convenienceFee: this.childFormData.convenienceFee,
          convenienceType: this.childFormData.convenienceType,
          status: this.childFormData.status,
        })
      }

      this.childFormData = {}
    },
    prepareServiceInfo(items) {
      return items.map(item => ({
        convenienceFee: Number(item.convenienceFee),
        convenienceType: item.convenienceType,
        serviceId: item.service.id,
        serviceStatus: item.status,
      }))
    },
    parentSubmitValidation() {
      this.fintechIdState = null
      this.statusState = null
      this.serviceInfoState = null
      if (this.formData.fintechId == null || this.formData.fintechId === '') {
        this.fintechIdState = false
      }
      if (this.formData.status == null || this.formData.status === '') {
        this.statusState = false
      }

      if (this.finalServiceData.length < 1 || this.finalServiceData === []) {
        this.serviceInfoState = false
      }

      if (this.fintechIdState !== false && this.statusState !== false && this.serviceInfoState !== false) {
        return true
      }
      return false
    },
    saveFintechInstitutionService() {
      if (!this.parentSubmitValidation()) {
        return
      }
      const serviceInfo = this.prepareServiceInfo(this.finalServiceData)
      if (!serviceInfo || serviceInfo === []) {
        FemsToastrService.error('At least one service needs to be included')
        return
      }

      const params = {
        institutionId: this.institutionId,
        fintechId: this.formData.fintechId,
        status: this.formData.status,
        serviceInfo: JSON.stringify(serviceInfo),
      }

      FintechInstituteService.create(params).then(
        response => {
          const res = response.data
          if (res.status) {
            this.resetFintechInstitutionService()
            this.getFintechInstitutionServiceGridData()
            FemsToastrService.success(res.message)
            this.$bvModal.hide('fintech-institution-service-modal')
          } else {
            FemsToastrService.error(res.message)
          }
        },

      ).catch(err => {
        FemsToastrService.error(err.message)
      })
    },
    updateFintechInstitutionService() {
      if (!this.parentSubmitValidation()) {
        return
      }
      const serviceInfo = this.prepareServiceInfo(this.finalServiceData)
      if (!serviceInfo || serviceInfo === []) {
        FemsToastrService.error('At least one service needs to be included')
        return
      }

      const params = {
        institutionId: this.institutionId,
        fintechId: this.formData.fintechId,
        status: this.formData.status,
        serviceInfo: JSON.stringify(serviceInfo),
      }

      FintechInstituteService.update(params, this.updateId).then(
        response => {
          const res = response.data
          if (res.status) {
            this.resetFintechInstitutionService()
            this.getFintechInstitutionServiceGridData()
            FemsToastrService.success(res.message)
            this.$bvModal.hide('fintech-institution-service-modal')
          } else {
            FemsToastrService.error(res.message)
          }
        },
      ).catch(
        err => {
          FemsToastrService.error(err.message)
        },
      )
    },
    getFintechInstitutionServiceGridData() {
      if (!this.institutionId) {
        FemsToastrService.error('Please select valid institution')
      }
      FintechInstituteService.getAll(this.institutionId).then(
        response => {
          const res = response.data
          if (res.status) {
            this.rows = res.data
          } else {
            this.rows = []
          }
        },
      ).catch(() => {
        this.rows = []
      })
    },
    refreshGrid() {
      this.getFintechInstitutionServiceGridData()
    },
    resetFintechInstitutionService() {
      this.updateId = null
      this.formData = {
        serviceType: 1,
        status: 1,
      }
      this.childFormData = {}
      this.finalServiceData = []

      this.fintechIdState = null
      this.statusState = null
      this.serviceInfoState = null

      this.childServiceState = null
      this.childConvenienceTypeState = null
      this.childConvenienceFeeState = null
      this.childStatusState = null
      this.paymentServiceUpdateMode = false
    },
    openModal() {
      if (!this.institutionId) {
        FemsToastrService.error('Institute must be selected')
        return
      }
      this.resetFintechInstitutionService()
      if (!this.institutionId) {
        FemsToastrService.error('Please select valid institution')
        return
      }
      this.getFintechs()
      this.getFintechInstitutionServiceService()
      this.$bvModal.show('fintech-institution-service-modal')
    },
    editRow(rowData) {
      this.updateId = rowData.id
      FintechInstituteService.getOne(rowData.id).then(response => {
        const res = response.data
        this.formData = {
          id: rowData.id,
          fintechId: res.data.fintech_id,
          status: res.data.status,
        }

        res.data.child.forEach(item => {
          this.finalServiceData.push({
            id: item.id,
            service: item.fintech_service,
            convenienceFee: item.convenience_fee,
            convenienceType: item.convenience_type,
            status: item.service_status,
          })
        })
        this.getFintechs()
      })

      this.$bvModal.show('fintech-institution-service-modal')
    },
    deleteRow(rowData) {
      this.$swal({
        title: 'Are you sure to delete?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          FintechInstituteService.delete(rowData.id).then(response => {
            const res = response.data
            if (res.status) {
              this.resetFintechInstitutionService()
              this.getFintechInstitutionServiceGridData()
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: res.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            } else {
              FemsToastrService.error(res.message)
            }
          })
        }
      })
    },
    editPaymentService(rowData) {
      this.childFormData = {
        index: rowData.originalIndex,
        service: rowData.service,
        convenienceFee: rowData.convenienceFee,
        convenienceType: rowData.convenienceType,
        status: rowData.status,
      }
      this.paymentServiceUpdateMode = true
    },
    deletePaymentService(rowData) {
      this.finalServiceData = this.finalServiceData.splice(1, rowData.originalIndex)
    },
    // Audit Activity
    onActivityLog() {
      this.activityModal = true
      this.$nextTick(() => {
        this.$refs.activityModal.modalShow()
      })
    },
  },
}
</script>
<style lang="scss">
svg.feather.feather-refresh-cw {
  height: 15px;
  width: 15px;
}
.text-danger{
  color: red;
}
</style>
